@import "../scss/media";

.page-with-sidebar {
  @include media ('>=large') {
    padding: 0 22px 0 362px;
  }

  .sidebar-expanded {
    padding: 0 22px 0 362px;
  }
}
