//@import "media";
@import "variables";
@import "page_with_sidebar";

$theme-colors: (
  'primary': $brand-primary-color,
  'secondary': $brand-secondary-color,
  'tertiary': $brand-tertiary-color,
  'danger': $brand-danger-color,
  'success': $brand-success-color,
  'warning': $brand-warning-color,
  'info': $brand-info-color,
  'light': $brand-light-color,
  'dark': $brand-dark-color
);

@import "~bootstrap/scss/bootstrap";

html, body {
  color: $color-white;
  background-color: $color-dark-grey;
}

* {
  font-family: $font-family;
}

a {
  color: $brand-tertiary-color;
  text-decoration: none;

  &:hover {
    opacity: 0.6;
    color: $brand-tertiary-color;

    &.btn {
      opacity: 1;
    }
  }
}

.text-primary {
  color: $brand-primary-color !important;
}
.text-secondary {
  color: $brand-secondary-color !important;
}
.text-tertiary {
  color: $brand-tertiary-color !important;
}
.text-grey {
  color: $color-grey;
}

h1 {
  color: $brand-tertiary-color;

  @include media ('<large') {
    text-align: center;
  }
}

h2 {
  color: $brand-primary-color;
}

.card {
  --bs-card-bg: #1B1B1B;
  overflow: hidden;

  img {
    border-radius: 0;
  }
}

a.card:hover {
  --bs-card-bg: #151515;
}

button:active,
button:focus {
  outline: 0;
  box-shadow: none !important;
}

.nav-tabs {
  border-bottom-color: $brand-primary-color;

  .nav-link {
    margin: 0 1px;
    color: $brand-tertiary-color;

    &:hover,
    &.active {
      color: $color-white;
      border-color: $brand-primary-color;
      background-color: $brand-primary-color;
    }
  }
}

.ace_content,
.ace_editor,
.ace_editor * {
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
}
