$font-family: 'Open Sans', sans-serif;

$brand-primary-color: #27a2c7;
$brand-secondary-color: #202349;
$brand-tertiary-color: #a0d4cd;
$brand-danger-color: #dc3545;
$brand-success-color: #4CAF50;
$brand-warning-color: #ffc107;
$brand-info-color: #17a2b8;
$brand-light-color: #f8f9fa;
$brand-dark-color: #333333;

$color-white: #ffffff;
$color-black: #000000;
$color-grey: #888888;
$color-dark-grey: #222222;
