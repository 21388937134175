@import "../../../scss/variables";

.offCanvas {
  background-color: $color-dark-grey !important;

  button {
    filter: brightness(0) invert(1);
  }
}

.navbar {
  background-color: $brand-secondary-color;

  .navLinks {
    > div,
    > a {
      color: $color-white !important;
    }
  }

  .navbarBrand,
  a[role="button"] {
    color: $color-white !important;
  }

  button {
    filter: brightness(0) invert(1);
  }
}
